import React from 'react';
import {makeStyles} from "@mui/styles";
import {GRAY, SECONDARY_COLOR, WHITE} from "../../../constants/Colors";
import {Grid, IconButton, Tooltip, Typography} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import EditIcon from "@mui/icons-material/Edit";
import UpdateUSDRate from "./UpdateUSDRate";
import {AuthenticationContext} from "../../../contexts/AuthContext";


const useStyles = makeStyles((theme) => ({
    boxContainer: {
        backgroundColor: SECONDARY_COLOR,
        padding: 6,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    itemContainer: {
        backgroundColor: GRAY,
        width: '100%',
        padding: 3,
        marginTop: 3
    }
}));

const styles = {
    container: {
        paddingTop: 2,
    },
    title: {
        fontWeight: 200,
        fontFamily: 'MontserratLight !important',
        textAlign: 'center'
    },
}

export default function ChartHeader({selected,USDRate}) {

    const classes = useStyles();
    const isMobile = useResponsive('down', 'md');

    const authenticationContext = React.useContext(AuthenticationContext);
    const {userAccount} = authenticationContext;

    return (
        <Grid container sx={styles.container} mb={0.5}>
            <Grid item xs={2} sm={2} md={2}
                  pr={{xs: 0.5, sm: 0.5, md: 0.5}}
            >
                <div className={classes.boxContainer}>
                    <Typography variant='body1'
                                sx={[styles.title, { color: WHITE}]}
                    >
                        Floors
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5}
                  pr={{xs: 0.5, sm: 0.5, md: 0.5}}
            >
                <div className={classes.boxContainer}>
                    <Typography variant='body1'
                                sx={[styles.title, { color: WHITE}]}
                    >
                        Units
                    </Typography>
                </div>
            </Grid>
            {
                !(selected === 'PENTO') ? null :
                    <Grid item xs={1.5} sm={1.5} md={1.5}
                          pr={{xs: 0.5, sm: 0.5, md: 0.5}}
                    >
                        <div className={classes.boxContainer}>
                            <Typography variant='body1'
                                        sx={[styles.title, { color: WHITE}]}
                            >
                                Rooms
                            </Typography>
                        </div>
                    </Grid>
            }
            <Grid item xs={2} sm={2} md={2}
                  pr={{xs: 0.5, sm: 0.5, md: 0.5}}
            >
                <div className={classes.boxContainer}>
                    <Typography variant='body2'
                                sx={[styles.title, { color: WHITE}]}
                    >
                        Gross Area
                    </Typography>
                </div>
            </Grid>
            {/*<Grid item xs={1} sm={1} md={1}*/}
            {/*      pr={{xs: 0.5, sm: 0.5, md: 0.5}}*/}
            {/*>*/}
            {/*    <div className={classes.boxContainer}>*/}
            {/*        <Typography variant='body2'*/}
            {/*                    sx={[styles.title, { color: WHITE}]}*/}
            {/*        >*/}
            {/*            Net Area*/}
            {/*        </Typography>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
            <Grid item xs={2.5} sm={2.5} md={2.5} pr={{xs: 0.5, sm: 0.5, md: 0.5}}>
                <div className={classes.boxContainer}>
                    <Typography variant='body1'
                                sx={[styles.title, { color: WHITE}]}
                    >
                        Price per sq.ft.
                        {/*<br/>*/}
                        {/*<span style={{fontSize: 10}}>1 USD = {USDRate?.usdRate}</span>*/}
                    </Typography>
                    {/*{*/}
                    {/*    !(userAccount?.permissions?.includes('EDIT_FLOOR_PRICE')) ? null :*/}
                    {/*        <UpdateUSDRate/>*/}
                    {/*}*/}
                </div>
            </Grid>
            <Grid item xs={2.5} sm={2.5} md={2.5} pr={{xs: 0.5, sm: 0.5, md: 0.5}}>
                <div className={classes.boxContainer}>
                    <Typography variant='body1'
                                sx={[styles.title, { color: WHITE}]}
                    >
                        Price
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
}
